export default [
  {
    path: 'customize',
    name: 'customize',
    redirect: { name: 'customize-booking-website-general' },
    meta: {
      title: 'Customize',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "customize" */ '@/views/Customize/CustomizePage.vue').then(value => value.default),
    children: [
      {
        path: 'booking-website/general',
        name: 'customize-booking-website-general',
        meta: {
          title: 'customize_general',
          breadcrumb: 'general',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/General/CustomizeBookingWebsiteGeneral.vue').then(value => value.default)
      },
      {
        path: 'booking-website/theme-and-appearance',
        name: 'customize-theme-and-appearance',
        meta: {
          title: 'theme_and_appearance',
          breadcrumb: 'theme_and_appearance',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/ThemeAndAppearance/CustomizeThemeAndAppearance.vue').then(value => value.default)
      },
      {
        path: 'booking-website/hero-section',
        name: 'hero_section',
        meta: {
          title: 'hero_section',
          breadcrumb: 'hero_section',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/HeroSection/HeroSection.vue').then(value => value.default)
      },
      {
        path: 'booking-website/footer-section',
        name: 'customize-footer-section',
        meta: {
          title: 'footer_section',
          breadcrumb: 'footer_section',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/FooterSection/FooterSection.vue').then(value => value.default)
      },
      {
        path: 'booking-website/employees-page',
        name: 'customize-employees-page',
        meta: {
          title: 'employees_page',
          breadcrumb: 'employees_page',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/EmployeePage/CustomizeEmployeePage.vue').then(value => value.default)
      },
      {
        path: 'booking-website/services-page',
        name: 'customize-services-page',
        meta: {
          title: 'services_page',
          breadcrumb: 'services_page',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/ServicesPage/CustomizeServicesPageSettings.vue').then(value => value.default)
      },
      {
        path: 'booking-website/custom-code',
        name: 'customize-custom-code',
        meta: {
          title: 'custom_code',
          breadcrumb: 'custom_code',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-website" */ '@/views/Customize/BookingWebsite/CustomCode/CustomCode.vue').then(value => value.default)
      },
      {
        path: 'booking-form-steps/general',
        name: 'customize-booking-form-steps-general',
        meta: {
          title: 'customize_general',
          breadcrumb: 'general',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-form" */ '@/views/Customize/BookingFormSteps/General/CustomizeBookingFormStepsGeneral.vue').then(value => value.default)
      },
      {
        path: 'booking-form-steps/employees',
        name: 'customize-booking-form-steps-employees',
        meta: {
          title: 'employees',
          breadcrumb: 'employees',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-form" */ '@/views/Customize/BookingFormSteps/Employees/CustomizeBookingFormStepsEmployee.vue').then(value => value.default)
      },
      {
        path: 'booking-form-steps/customers',
        name: 'customize-booking-form-steps-customers',
        meta: {
          title: 'customers',
          breadcrumb: 'customers',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-form" */ '@/views/Customize/BookingFormSteps/Customers/CustomizeCustomerSettings.vue').then(value => value.default)
      },
      {
        path: 'booking-form-steps/thank-you-page',
        name: 'customize-booking-form-steps-thank-you-page',
        meta: {
          title: 'thank_you_page',
          breadcrumb: 'thank_you_page',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-booking-form" */ '@/views/Customize/BookingFormSteps/ThankYouPage/CustomizeThankYouPageSettings.vue').then(value => value.default)
      },
      {
        path: 'language',
        name: 'customize-languages',
        meta: {
          title: 'language',
          breadcrumb: 'language',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-language" */ '@/views/Customize/Language/Language.vue').then(value => value.default)
      },
      {
        path: 'labels',
        name: 'customize-labels',
        meta: {
          title: 'labels',
          breadcrumb: 'labels',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-labels" */ '@/views/Customize/Labels/Labels.vue').then(value => value.default)
      },
      {
        path: 'custom-fields/:type',
        name: 'customize-custom-fields',
        meta: {
          title: 'custom_fields',
          breadcrumb: 'custom_fields',
          requiresAuth: true,
          requiredPermissions: 'edit_settings'
        },
        component: () => import(/* webpackChunkName: "customize-custom-fields" */ '@/views/Customize/CustomFields/CustomizeCustomFields.vue').then(value => value.default)
      },
      {
        path: 'chatBot',
        name: 'customize-chat-bot',
        meta: {
          title: 'chatbot',
          breadcrumb: 'chatbot',
          requiresAuth: true,
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "customize-labels" */ '@/views/Customize/ChatBot/ChatBot.vue').then(value => value.default)
      },
    ]
  },
]
