const getFirstAvailableRoute = (routes, userPermissions) => {

  let childrenRoutes = []

  // get children routes for the main route
  for (const route of routes) {
    if (route.path === '/') {
      childrenRoutes = route.children
      break
    }
  }

  for (const route of childrenRoutes) {
    // Check the route
    if (route.meta) {
      let routeMetaRequiredPermissions = route.meta.requiredPermissions

      if (routeMetaRequiredPermissions) {
        if (typeof routeMetaRequiredPermissions === 'string') {
          routeMetaRequiredPermissions = [routeMetaRequiredPermissions]
        }

        if (routeMetaRequiredPermissions.filter(routeMetaRequiredPermission => userPermissions.includes(routeMetaRequiredPermission)).length) {
          return route.name
        }
      }
    }

    // Check route children
    if (!route.meta && route.children) {
      for (const childrenRoute of route.children) {
        if (childrenRoute.meta) {
          let routeMetaRequiredPermissions = childrenRoute.meta.requiredPermissions

          if (routeMetaRequiredPermissions) {
            if (typeof routeMetaRequiredPermissions === 'string') {
              routeMetaRequiredPermissions = [routeMetaRequiredPermissions]
            }

            if (routeMetaRequiredPermissions.filter(routeMetaRequiredPermission => userPermissions.includes(routeMetaRequiredPermission)).length) {
              return childrenRoute.name
            }
          }
        }
      }
    }
  }

  return null
}

export default getFirstAvailableRoute
